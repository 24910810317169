export const createCookie = (
  name: string,
  value: string,
  domain: string,
  maxAge: number,
  secureFlag: string,
) => {
  return `${name}=${value}; Path=/; Domain=${domain}; Max-Age=${maxAge}; SameSite=Lax; ${secureFlag};`
}

export const getCookie = (name: string) => {
  const cookieArr = document.cookie.split(';')
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].trim()

    if (cookiePair.startsWith(name + '=')) {
      return cookiePair.substring(name.length + 1)
    }
  }
  return null
}

export const deleteCookie = (name: string, domain: string) => {
  document.cookie = `${name}=; Domain=${domain}; Max-Age=0; Path=/;`
}
